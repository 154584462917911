import axios from '../utils/axios';

export const getChaptersByCourse = (courseId) => {
  return axios.get('/CourseChapters/getChaptersByCourse', {
    params: {
      courseId,
    },
  });
};

export const createCourseChapter = (body, options) => {
  return axios.post('/CourseChapters/createChapter', body, options);
};

export const updateCourseChapter = (id, body, options) => {
  return axios.patch('/CourseChapters/updateChapter', body, {
    params: {
      id,
    },
  }, options);
};

export const deleteCourseChapter = (id) => {
  return axios.delete('/CourseChapters/deleteChapter', {
    params: {
      id,
    },
  });
};
