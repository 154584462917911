import React from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  ListItemSecondaryAction,
  Button,
  Divider,
  Chip,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import DragIndicator from "@material-ui/icons/DragIndicator";

const DraggableList = ({
  items,
  setItems,
  handleOrderChange,
  handleDelete,
  handleEdit,
  handleView,
  handleFileUpload,
  handleAddUrl,
}) => {
  const [t] = useTranslation(["common", "tooltips", "newTranslations"]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    setItems(reorderedItems);

    handleOrderChange(reorderedItems);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            <List>
              {items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        ...provided.draggableProps.style,
                        background: snapshot.isDragging ? "rgb(235,235,235)" : "inherit",
                        transform: provided.draggableProps.style?.transform || "none",
                      }}
                    >
                      <ListItem>
                        <ListItemIcon>
                          <DragIndicator />
                        </ListItemIcon>
                        <ListItemText primary={item.title} />
                        {!snapshot.isDragging && (
                          <ListItemSecondaryAction>
                            <IconButton onClick={() => handleDelete(item.id)}>
                              <DeleteIcon />
                            </IconButton>

                            <IconButton onClick={() => handleEdit(item.id)}>
                              <EditIcon />
                            </IconButton>

                            {handleView && (
                              <Button
                                onClick={() => handleView(item.id)}
                                variant="outlined"
                                color="primary"
                                size="small"
                              >
                                {t('newTranslations:viewSubchapters')}
                              </Button>
                            )}
                          </ListItemSecondaryAction>
                        )}
                      </ListItem>
                      {handleFileUpload && (
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '12px 16px', paddingLeft: '70px' }}>
                          <span>
                            {item.assetType && (
                              <Chip
                                label={item.assetType.toUpperCase()}
                                size="small"
                                style={{
                                  backgroundColor:
                                    item.assetType === 'pdf' ? 'rgba(255, 0, 0, 0.1)' : 'rgba(0, 0, 255, 0.1)',
                                  color: item.assetType === 'pdf' ? 'red' : 'blue',
                                  marginRight: '8px',
                                  fontSize: '0.75rem',
                                  fontWeight: 'bold',
                                  cursor: 'inherit',
                                }}
                              />
                            )}
                            {item.url && (
                              <Chip
                                label="URL"
                                size="small"
                                style={{
                                  backgroundColor: 'rgba(56, 142, 60, 0.1)',
                                  color: '#2e7d32',
                                  marginRight: '8px',
                                  fontSize: '0.75rem',
                                  fontWeight: 'bold',
                                  cursor: 'inherit',
                                }}
                              />
                            )}
                            {item.url ? item.url : (item.asset ? item.asset.fileName : t('newTranslations:noUploadedFile'))}
                          </span>

                          <input
                            type="file"
                            id={`file-upload-${item.id}`}
                            style={{ display: 'none' }}
                            accept=".pdf,.mp4,.mov,.avi"
                            onChange={(e) => {
                              const file = e.target.files[0];
                              if (file) {
                                const formData = new FormData();
                                formData.append('file', file);
                                formData.append('assetType', file.type.startsWith('video') ? 'video' : 'pdf');
                                handleFileUpload(item.id, formData);
                              }
                            }}
                          />

                          <div style={{display: 'flex', columnGap: 12}}>
                            <Button
                              onClick={() => document.getElementById(`file-upload-${item.id}`).click()}
                              variant="outlined"
                              color={item.assetId ? "primary" : "secondary"}
                              size="small"
                            >
                              {item.assetId ? t('newTranslations:changeFile') : t('newTranslations:uploadFile')}
                            </Button>

                            <Button
                              onClick={() => handleAddUrl(item.id)}
                              variant="outlined"
                              color="primary"
                              size="small"
                            >
                              {item.url ? t('newTranslations:changeUrl') : t('newTranslations:addUrl')}
                            </Button>
                          </div>
                        </div>
                      )}

                      { index !== items.length - 1 && (
                        <Divider />
                      )}

                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </List>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableList;
