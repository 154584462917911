import React from 'react';
import { Redirect } from "react-router-dom";

import AdminDashboard from './layouts/AdminDashboard';
import Website from './layouts/Website';

import ActivateAccount from './views/ActivateAccount';
import ContactCampaignKCQSelection from './views/ContactCampaignKCQSelection'
import ContactCampaignSetSelection from './views/ContactCampaignSetSelection';
import Error404 from './views/Error404';
import LandingPage from './views/LandingPage';
import CoursesView from './views/Courses';
import LearningHubAnon from './views/LearningHubAnon';
import MyProfile from './views/MyProfile';
import Notifications from './views/Notifications';
import ResetPassword from './views/ResetPassword';
import SearchResults from './views/SearchResults';
import TalentAlertActivateAccount from './views/TalentAlertActivateAccount';
import TalentAlertContactTermsAndConditions from './views/TalentAlertContacts/TalentAlertContactTermsAndConditions';
import UnAuthTalentAlertContactCandidateProfile from './views/TalentAlertContacts/UnAuthTalentAlertContactCandidateProfile';
import UnAuthTalentAlertContactDashboard from './views/TalentAlertContacts/UnAuthTalentAlertContactDashboard';

import BullhornRedirectUri from './views/BullhornRedirectUri';

import AdminRoutes from './routes/admin-routes';
import CandidateRoutes from './routes/candidate-routes';
import ConsultantRoutes from './routes/consultant-routes';
import ContactRoutes from './routes/contact-routes';

import { ADMIN, CANDIDATE, CONSULTANT, CONTACT, COREADMIN, SYSTEMADMIN } from './constants/roles';

const isLoggedIn = (store) => {
    if (store.getState().session.loggedIn) {
        return true;
    }

    return false;
};

const getRedirectPathname = (path, params) => {
    const pathParams = path.match(/(:\w*)/g);
    
    if (!pathParams || !pathParams.length) {
        return path;
    }
    
    let newPath = path;

    for (const pathParam of pathParams) {
        newPath = newPath.replace(pathParam, params[pathParam.substring(1)]);
    }

    return newPath;
};

export default function getRoutes(store) {

    let userRoleRoutes = [];

    if (isLoggedIn(store)) {
        switch (store.getState().session.roleType) {
            case COREADMIN:
            case SYSTEMADMIN:
            case ADMIN:
                userRoleRoutes = AdminRoutes(store);
                break;
            case CANDIDATE:
                userRoleRoutes = CandidateRoutes(store);
                break;
            case CONSULTANT:
                userRoleRoutes = ConsultantRoutes(store);
                break;
            case CONTACT:
                userRoleRoutes = ContactRoutes(store);
                break;
            default:
        }
    }

    const validRoutes = [
        ...AdminRoutes(store),
        ...CandidateRoutes(store),
        ...ConsultantRoutes(store),
        ...ContactRoutes(store)
    ].map((route) => ({
        path: route.path,
        exact: route.exact,
        component: (props) => <Redirect to={{ pathname: '/', state: { redirectPathname: getRedirectPathname(route.path, props.match.params) } }} />
    }));

    return [
        {
          path: '/error-404',
          exact: true,
          component: Error404,
        },

        {
            path: '/dashboard',
            component: AdminDashboard,
            routes: [
                ...userRoleRoutes,

                {
                    path: '/dashboard/search-results',
                    exact: true,
                    component: () => isLoggedIn(store) ? <SearchResults /> : <Redirect to='/' />
                },
                {
                    path: '/dashboard/my-profile',
                    exact: true,
                    component: () => isLoggedIn(store) ?
                        <MyProfile /> :
                        <Redirect to={{ pathname: '/', state: { redirectPathname: '/dashboard/my-profile' } }} />
                },
                {
                    path: '/dashboard/retained-recruiter-academy',
                    exact: true,
                    component: () => isLoggedIn(store) ?
                        <CoursesView /> :
                        <Redirect to={{ pathname: '/', state: { redirectPathname: '/dashboard/retained-recruiter-academy' } }} />
                },
                {
                    path: '/dashboard/retained-recruiter-academy/:courseId',
                    exact: true,
                    component: (props) => isLoggedIn(store) ?
                        <LearningHubAnon courseId={ Number(props.match.params.courseId) } /> :
                        <Redirect to={{ pathname: '/', state: { redirectPathname: `/dashboard/retained-recruiter-academy/${props.match.params.courseId}` } }} />
                },
                {
                    path: '/dashboard/notifications',
                    exact: true,
                    component: () => isLoggedIn(store) ?
                        <Notifications /> :
                        <Redirect to={{ pathname: '/', state: { redirectPathname: '/dashboard/notifications' } }} />
                },

                ...validRoutes,

                {
                    component: () => <Redirect to='/errors/error-404' />
                }
            ]
        },

        {
            path: '/talent-alert/:campaignId',
            exact: true,
            component: (props) => (
                <UnAuthTalentAlertContactDashboard campaignId={ Number(props.match.params.campaignId) } consultancyId={ Number(store.getState().session.consultancyId) } loggedIn={ isLoggedIn(store) } />
            )
        },
        {
            path: '/talent-alert/:campaignId/:candidateId',
            exact: true,
            component: (props) => (
                <UnAuthTalentAlertContactCandidateProfile campaignId={ Number(props.match.params.campaignId) } consultancyId={ Number(store.getState().session.consultancyId) } candidateId={ Number(props.match.params.candidateId) } loggedIn={ isLoggedIn(store) } />
            )
        },
        {
            path: '/terms-and-conditions/:campaignId',
            exact: true,
            component: (props) => (
                <TalentAlertContactTermsAndConditions campaignId={ Number(props.match.params.campaignId) } consultancyId={ Number(store.getState().session.consultancyId) } />
            )
        },

        {
            route: '*',
            component: Website,
            routes: [
                {
                    path: '/',
                    exact: true,
                    component: LandingPage
                },
                {
                    path: '/home',
                    exact: true,
                    component: LandingPage
                },
                {
                    path: '/activate-account/:activation_token',
                    exact: true,
                    component: (props) => <ActivateAccount activation_token={ props.match.params.activation_token } />
                },
                {
                    path: '/activate-account/talent-alert/:activation_token',
                    exact: true,
                    component: (props) => <TalentAlertActivateAccount activationToken={ props.match.params.activation_token } />
                },
                {
                    path: '/password-reset/:reset_password_token',
                    exact: true,
                    component: (props) => <ResetPassword reset_password_token={ props.match.params.reset_password_token } />
                },
                {
                    path: '/key-competency-question-selection/:accessToken',
                    exact: true,
                    component: (props) => <ContactCampaignKCQSelection contact_access_token={ props.match.params.accessToken } />
                },
                {
                    path: '/key-competency-question-set-selection/:accessToken',
                    exact: true,
                    component: (props) => <ContactCampaignSetSelection contact_access_token={ props.match.params.accessToken } />
                },
                {
                  path: '/bullhorn/auth',
                  exact: true,
                  component: () => <BullhornRedirectUri />
                },

                {
                    component: () => <Redirect to='/errors/error-404' />
                }
            ]
        }
    ];

};
