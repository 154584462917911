import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { Grid, AppBar } from '@material-ui/core';
import AnalyticsIcon from '@material-ui/icons/AssessmentSharp';
import ChildCareIcon from '@material-ui/icons/ChildCare';
import DetailsIcon from '@material-ui/icons/Details';
import ExtensionSharpIcon from '@material-ui/icons/ExtensionSharp';
import GroupIcon from '@material-ui/icons/Group';
import PaletteIcon from '@material-ui/icons/Palette';
import SettingsIcon from '@material-ui/icons/Settings';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import { makeStyles } from '@material-ui/styles';

import * as consultancyApi from './../../api/consultancyApi';
import * as SystemAdminApi from './../../api/SystemAdminApi';

import { UPDATE_BREADCRUMBS } from './../../actions';

import Analytics from './Analytics';
import ConsultancyAdminModuleManagement from './ConsultancyAdminModuleManagement';
import ConsultancyDetail from './ConsultancyDetails';
import Settings from './Settings';
import SystemAdminConsultancyModuleManagement from './SystemAdminConsultancyModuleManagement';
import ThemeManagement from './ThemeManagement';
import UsersandGroups from './UsersandGroups';

import ChildConsultancies from './../../components/ChildConsultancies';
import Breadcrumbs from './../../components/NavBreadCrumbs';
import { TabPanel } from './../../components/TabPanel';
import { ContentWrapper } from './../../components/LayoutComponents/ContentWrapper';
import CustomTabs from './../../components/LayoutComponents/CustomTabs';
import { PageTitle } from './../../components/LayoutComponents/PageTitle';

import * as roles from './../../constants/roles';
import * as subscriptionPlansIds from './../../constants/subscriptionPlansIds';
import CoursesManagement from './CoursesManagement';

const useStyles = makeStyles((theme) => ({
  gridRow: {
    marginTop: '20px'
  },
  appBar: {
    boxShadow: 'none',
    backgroundColor: 'transparent'
  },
  tabIcon: { fontSize: '1.4rem' }
}));

const ConsultancyManagement = ({ consultancyId }) => {

  const classes = useStyles();

  const dispatch = useDispatch();
  
  const [t] = useTranslation(['candidates', 'common', 'consultancy', 'newTranslations']);

  const roleType = useSelector(state => state.session.roleType);
  const isConsultancyWithFullSubscriptionPlan = useSelector((state) => {
    if (state.session.consultancySubscriptionPlanId === subscriptionPlansIds.FULL) {
      return true;
    }

    return false;
  });

  const [consultancyData, setConsultancyData] = useState({});
  const [currentTab, setCurrentTab] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const sysAdmin = roleType === roles.SYSTEMADMIN || roleType === roles.COREADMIN;

  const tabs = [
    {
      value: 0,
      label: t('consultancy:consultancyDetails'),
      icon: <DetailsIcon />
    },
    {
      value: 1,
      label: t('consultancy:users&Groups'),
      icon: <GroupIcon />
    },
    {
      value: 7,
      label: t('common:settings'),
      icon: <SettingsIcon />
    }
  ];

  if (sysAdmin) {
    tabs.splice(2, 0, {
      value: 2,
      label: 'Theme Management',
      icon: <PaletteIcon />
    });

    tabs.splice(3, 0, {
      value: 4,
      label: t('consultancy:childConsultancies'),
      icon: <ChildCareIcon />
    });
    
    tabs.splice(4, 0, {
      value: 6,
      label: t('newTranslations:retainedrecruiteracademy'),
      icon: <LocalLibraryIcon />
    });
  }

  if (isConsultancyWithFullSubscriptionPlan) {
    const moduleManagmentIndex = sysAdmin ? 3 : 2;
    tabs.splice(moduleManagmentIndex, 0, {
      value: 3,
      label: t('consultancy:moduleManagement'),
      icon: <ExtensionSharpIcon />
    });

    const analyticsIndex = sysAdmin ? 5 : 3;
    tabs.splice(analyticsIndex, 0, {
      value: 5,
      label: t('consultancy:analytics'),
      icon: <AnalyticsIcon />
    });
  }

  useEffect(() => {
    getConsultancyData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (sysAdmin) {
      setCrumbs();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consultancyData]);

  const getConsultancyData = () => {
    SystemAdminApi.getConsultancyUsers(consultancyId)
      .then((response) => {
        setConsultancyData(response.data);
      })
      .then(() => {
        setIsLoading(false);
      });
  };

  const setCrumbs = async () => {
    consultancyApi.getConsultancyTitle(consultancyId)
      .then((response) => {
        if (response.status === 200)
          dispatch({
            type: UPDATE_BREADCRUMBS,
            breadcrumbs: [
              {
                title: 'Consultancies',
                link: ''
              },
              {
                title: response.data,
                link: `/consultancies/${consultancyId}`
              }
            ]
          });
      });
  };

  const handleChange = (value) => {
    setCurrentTab(value);
  };

  return (
    <div style={{ overflow: "hidden", paddingBottom: "20px" }}>
      <PageTitle pageTitle={t("common:consultancyManagement")} />

      <ContentWrapper loading={ isLoading }>
        {
          sysAdmin && (
            <Breadcrumbs />
          )
        }

        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.gridRow}>
            <AppBar position="static" className={classes.appBar}>
              <CustomTabs tabs={ tabs } onChange={ handleChange } tabClassName={ classes.tab } />
            </AppBar>
          </Grid>

          <Grid item xs={12} className={classes.gridRow}>
            <TabPanel value={currentTab} index={0}>
              <ConsultancyDetail consultancyId={consultancyId} />
            </TabPanel>

            <TabPanel value={currentTab} index={1}>
              <UsersandGroups
                usersAndGroups={consultancyData.usersAndGroups}
                consultancyId={consultancyId} />
            </TabPanel>

            {
              sysAdmin && (
                <TabPanel value={currentTab} index={2}>
                  <ThemeManagement consultancyId={consultancyId} />
                </TabPanel>
              )
            }

            {
              isConsultancyWithFullSubscriptionPlan && (
                <TabPanel value={currentTab} index={3}>
                  {
                    sysAdmin ? (
                      <SystemAdminConsultancyModuleManagement
                        consultancyId={consultancyData.id}
                      />
                    ) : (
                      <ConsultancyAdminModuleManagement
                        consultancyId={consultancyData.id}
                      />
                    )
                  }
                </TabPanel>
              )
            }

            {
              sysAdmin && (
                <TabPanel value={currentTab} index={4}>
                  <ChildConsultancies parentConsultancyId={consultancyId} />
                </TabPanel>
              )
            }

            {
              isConsultancyWithFullSubscriptionPlan && (
                <TabPanel value={currentTab} index={5}>
                  <Analytics consultancyId={consultancyId} />
                </TabPanel>
              )
            }

            {
              sysAdmin && (
                <TabPanel value={currentTab} index={6}>
                  <CoursesManagement consultancyId={consultancyData.id} />
                </TabPanel>
              )
            }

            <TabPanel value={currentTab} index={7}>
              <Settings consultancyId={consultancyId} />
            </TabPanel>
          </Grid>
        </Grid>
      </ContentWrapper>
    </div>
  );

}

export default ConsultancyManagement;
