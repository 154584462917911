import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalFormWrapper } from 'src/components/ModalFormWrapper';
import CreateCourseChapterForm from './CreateCourseChapterForm';
import * as courseChapterApi from '../../api/courseChapterApi';
import * as courseSubchapterApi from '../../api/courseSubchapterApi';

function CreateCourseChapterModal({ type, onSuccess, open, handleClose, id, order }) {
  const [t] = useTranslation(["common", "newTranslations"]);

  const onSubmit = async (values) => {
    if (type === 'chapter') {
      await courseChapterApi.createCourseChapter({
        ...values,
        courseId: id,
        order,
      });
    }
    else {
      await courseSubchapterApi.createCourseSubchapter({
        ...values,
        chapterId: id,
        order,
      });
    }

    onSuccess();
  };

  const handleModalClose = () => {
    handleClose();
  };

  return (
    <ModalFormWrapper
      formTitle={t(`newTranslations:${type === 'chapter' ? 'addCourseChapter' : 'addCourseSubchapter'}`)}
      handleOpen={open}
      handleClose={handleModalClose}
      maxWidth={"sm"}
    >
      <CreateCourseChapterForm
        type={type}
        onSubmit={onSubmit}
        style={{ width: "100%" }}
      />
    </ModalFormWrapper>
  );
}
export default CreateCourseChapterModal;
