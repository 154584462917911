import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import Swal from 'sweetalert2';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Link, Card, CardContent, Button, colors, useTheme } from "@material-ui/core";
import { BlockHeader } from "src/components/LayoutComponents/BlockHeader";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import * as IntegrationsApi from "../../api/integrationsApi";
import * as courseApi from '../../api/courseApi';
import * as courseChapterApi from "../../api/courseChapterApi";
import * as courseSubchapterApi from "./../../api/courseSubchapterApi";

import CustomFileDropzone from "src/components/CustomFileDropzone";
import { useSnackbar } from "notistack";

import logoPlaceholder from "src/assets/images/placeholder.png";
import iintroLogo from "src/assets/images/logos/i-intro_logo.png";
import taLogo from './../../assets/images/logos/i-intro_ta_subscription_logo.png';
import taProLogo from './../../assets/images/logos/i-intro_ta_pro_subscription_logo.png';

import { Go1LearningObjectCard } from "../../components/Cards/CourseCard";
import GooneLoginButton from "../../components/Integragions/GooneLoginButton.js";
import { ContentWrapper } from "../../components/LayoutComponents/ContentWrapper";
import { PageTitle } from "../../components/LayoutComponents/PageTitle";
import EditTitlesCard from "./EditTitlesCard";
import DraggableList from "./DraggableList";
import CreateCourseChapterModal from "./CreateCourseChapterModal";
import EditCourseChapterModal from "./EditCourseChapterModal";
import { LoadingModal } from "./../../components/LoadingModal";
import AddUrlForm from "./AddUrlForm";
import AddUrlModal from "./AddUrlModal";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  container: {
    padding: '0px 10px',
  },
  fields: {
    margin: theme.spacing(-1),
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  divider: {
    width: '100%',
    height: '1px',
    borderBottom: `1px solid ${colors.grey[200]}`,
    marginBottom: '12px',
  },
  submitButton: {
    width: '33%',
  },
  modalCard: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
      transform: `translate(${0}%, ${10}%)`,
    },
    [theme.breakpoints.up('md')]: {
      width: '50%',
      transform: `translate(${50}%, ${10}%)`,
    },
    '&:focus': {
      outline: 'none',
    },
  },
  closeButton: {
    minWidth: '32px',
    backgroundColor: colors.red[600],
    '&:hover': {
      backgroundColor: colors.red[400],
    },
    color: theme.palette.common.white,
    textTransform: 'none',
  },
  modalOverflow: {
    overflowY: 'scroll',
  },
  tooltip: {
    textAlign: 'center',
    padding: 2,
    margin: 0,
  },
}));

function CourseAdminSingleView({ ...props }) {
  const [t] = useTranslation(["common", "tooltips", "newTranslations"]);

  const history = useHistory();

  const classes = useStyles();

  const consultancyId = useSelector((state) => state.session.consultancyId);

  const [course, setCourse] = useState(null);
  const [courseChapters, setCourseChapters] = useState([]);

  const [open, setOpen] = useState(false);
  const [addUrlId, setAddUrlId] = useState(false);

  const [chapterToEdit, setChapterToEdit] = useState(null);

  const [chapterToView, setChapterToView] = useState(null);

  const [subchapters, setSubchapters] = useState([]);

  const [addType, setAddType] = useState('chapter');

  const [isUploadingAsset, setIsUploadingAsset] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  useEffect(() => {
    fetchCourse();
    fetchCourseChapters();
  }, []);

  useEffect(() => {
    if (!chapterToView) {
      return;
    }

    fetchCourseSubchapters();
  }, [chapterToView]);

  const fetchCourse = () => {
    courseApi.getCourseById(props.courseId, consultancyId)
      .then((response) => {
        setCourse(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const fetchCourseChapters = () => {
    courseChapterApi.getChaptersByCourse(props.courseId)
      .then((response) => {
        setCourseChapters(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const fetchCourseSubchapters = () => {
    courseSubchapterApi.getSubchaptersByChapterId(chapterToView)
      .then((response) => {
        setSubchapters(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const addCourseChapterSuccess = () => {
    setOpen(false);

    enqueueSnackbar(t(`snackbar:${addType === 'chapter' ? 'addCourseChapter' : 'addCourseSubchapter'}`), {
      variant: "success",
    });

    if (addType === 'chapter') {
      fetchCourseChapters();
    }
    else {
      fetchCourseSubchapters();
    }
  }

  const updateCourseChapterSuccess = () => {
    setChapterToEdit(null);

    enqueueSnackbar(t(`snackbar:${addType === 'chapter' ? 'updateCourseChapter' : 'updateCourseSubchapter'}`), {
      variant: "success",
    });

    if (addType === 'chapter') {
      fetchCourseChapters();
    }
    else {
      fetchCourseSubchapters();
    }
  }

  const addUrlSuccess = () => {
    setAddUrlId(null);

    fetchCourseSubchapters();
  }

  const deleteCourse = () => {
    Swal.fire({
      title: t('common:areYouSure'),
      text: t(`newTranslations:courseDeleteConfirmation`),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `${t('common:yes')}`,
      cancelButtonText: `${t('common:no')}`,
      confirmButtonColor: theme.palette.warning?.main ?
        theme.palette.warning.main :
        theme.palette.primary.main,
    }).then(resp => {
      if (!resp.isConfirmed) return;

      courseApi.deleteCourse(course.id)
        .then(() => {
          history.push('/dashboard/all-courses');
          enqueueSnackbar(t("snackbar:deleteCourse"), {
            variant: "success",
          });
        })
        .catch(() => {
          enqueueSnackbar(t("snackbar:deleteCourseFailed"), {
            variant: "error",
          });
        });
    });
  }

  const handleUploadCampaignLogo = (value) => {
    return courseApi.uploadThumbnail(course.id, value)
      .catch((error) => {
        enqueueSnackbar(t("common:courseImageUpdateError"), {
          variant: "error",
        });

        console.error(error);
      });
  };

  const handleOrderChange = (reorderedItems, type = 'chapter') => {
    const promises = [];

    for (let i = 0; i < reorderedItems.length; i++) {
      const chapter = reorderedItems[i];

      if (chapter.order === i + 1) {
        continue;
      }

      if (type === 'chapter') {
        promises.push(
          courseChapterApi.updateCourseChapter(chapter.id, { order: i + 1 })
        );
      }
      else {
        promises.push(
          courseSubchapterApi.updateCourseSubchapter(chapter.id, { order: i + 1 })
        );
      }
    }

    Promise.all(promises);
  }

  const handleDeleteChapter = (id, func) => {
    Swal.fire({
      title: t('common:areYouSure'),
      text: t(`newTranslations:courseDeleteConfirmation`),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `${t('common:yes')}`,
      cancelButtonText: `${t('common:no')}`,
      confirmButtonColor: theme.palette.warning?.main ?
        theme.palette.warning.main :
        theme.palette.primary.main,
    }).then(resp => {
      if (!resp.isConfirmed) return;

      func(id);
    });
  }

  const deleteCourseChapter = (id) => {
    courseChapterApi.deleteCourseChapter(id).
      then(() => {
        const filtered = courseChapters.filter(chapter => chapter.id !== id);

        setCourseChapters(filtered);

        handleOrderChange(filtered);

        enqueueSnackbar(t("snackbar:deleteCourseChapter"), {
          variant: "success",
        })
      })
      .catch((error) => {
        enqueueSnackbar(t("common:deleteCourseChapterFailed"), {
          variant: "error",
        });
      });

    setChapterToView(null);
  }

  const deleteCourseSubchapter = (id) => {
    courseSubchapterApi.deleteCourseSubchapter(id).
      then(() => {
        const filtered = subchapters.filter(chapter => chapter.id !== id);

        setSubchapters(filtered);

        handleOrderChange(filtered, 'subchaper');

        enqueueSnackbar(t("snackbar:deleteCourseSubchapter"), {
          variant: "success",
        })
      })
      .catch((error) => {
        enqueueSnackbar(t("common:deleteCourseSubchapterFailed"), {
          variant: "error",
        });
      });
  }

  const uploadCourseSubchapterAsset = (id, formData) => {
    setIsUploadingAsset(true);

    courseSubchapterApi.uploadCourseSubchapterAsset(id, formData)
      .then(() => {
        setIsUploadingAsset(false);

        fetchCourseSubchapters();
      })
  }

  const handleCourseChapterEdit = (id) => {
    setAddType('chapter');
    setChapterToEdit(id);
  }

  const handleCourseSubchapterEdit = (id) => {
    setAddType('subchapter');
    setChapterToEdit(id);
  }

  const getLogo = () => {
    if (course.thumbnail) {
      return course.thumbnail;
    }

    return logoPlaceholder;
  };

  return (
    <div style={{ overflow: "hidden", paddingBottom: "20px" }}>
      {course && (
        <>
          <PageTitle
            pageTitle={course.title}
            btnLabel={t('newTranslations:deleteCourse')}
            btnVariant="outlined"
            btnFunc={deleteCourse}
            btnLabel2={t('newTranslations:preview')}
            btnVariant2="contained"
            btnFunc2={() => history.push(`/dashboard/retained-recruiter-academy/${props.courseId}`)}
          />
          <ContentWrapper>
            <Grid container spacing={3}>
              <Grid item xs={12}></Grid>

              <Grid item xs={12} lg={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <EditTitlesCard course={course} onSuccess={() => fetchCourse()} />
                  </Grid>

                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <Grid container spacing={3}>
                          <Grid item xs={12} style={{ textAlign: "left" }}>
                            <BlockHeader
                              headerTooltip={t("tooltips:uploadCourseThumbnail")}
                              card
                              header={t("labels:manageThumbnail")}
                            />
                            <CustomFileDropzone
                              type="single"
                              format="image"
                              uploadFunction={handleUploadCampaignLogo}
                              placeHolderImage={ getLogo() }
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} lg={8}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <BlockHeader
                        card
                        header={t("newTranslations:chapters")}
                        btnLabel={t('newTranslations:addCourseChapter')}
                        btnFunc={() => { setOpen(true); setAddType('chapter') }}
                        btnVariant="contained"
                      />

                      <div className={classes.divider}></div>

                      {courseChapters && courseChapters.length > 0 ? (
                        <DraggableList
                          items={courseChapters}
                          setItems={setCourseChapters}
                          handleOrderChange={handleOrderChange}
                          handleDelete={(id) => handleDeleteChapter(id, deleteCourseChapter)}
                          handleEdit={handleCourseChapterEdit}
                          handleView={(id) => setChapterToView(id)}
                        />
                      ) : (
                        <span>{t('newTranslations:noCourseChapters')}</span>
                      )}
                    </CardContent>
                  </Card>
                </Grid>

                {chapterToView && (
                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <BlockHeader
                          card
                          header={t("newTranslations:subchapters") + ' - ' + courseChapters.filter(chapter => chapter.id === chapterToView)[0].title}
                          btnLabel={t('newTranslations:addCourseSubchapter')}
                          btnFunc={() => { setOpen(true); setAddType('subchapter') }}
                          btnVariant="contained"
                        />

                        <div className={classes.divider}></div>

                        {subchapters && subchapters.length > 0 ? (
                          <DraggableList
                            items={subchapters}
                            setItems={setSubchapters}
                            handleOrderChange={(data) => handleOrderChange(data, 'subchapter')}
                            handleDelete={(id) => handleDeleteChapter(id, deleteCourseSubchapter)}
                            handleEdit={handleCourseSubchapterEdit}
                            handleFileUpload={uploadCourseSubchapterAsset}
                            handleAddUrl={(id) => setAddUrlId(id)}
                          />
                        ) : (
                          <span>{t('newTranslations:noCourseSubchapters')}</span>
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                )}
              </Grid>
              </Grid>
            </Grid>
          </ContentWrapper>

          <CreateCourseChapterModal
            type={addType}
            onSuccess={addCourseChapterSuccess}
            open={open}
            handleClose={() => setOpen(false)}
            id={addType === 'chapter' ? course.id : chapterToView}
            order={addType === 'chapter' ? courseChapters.length + 1 : subchapters.length + 1}
          />

          {chapterToEdit && (
            <EditCourseChapterModal
              type={addType}
              onSuccess={updateCourseChapterSuccess}
              open={!!chapterToEdit}
              handleClose={() => setChapterToEdit(null)}
              chapter={(addType === 'chapter' ? courseChapters : subchapters).filter(chapter => chapter.id === chapterToEdit)[0]}
            />
          )}

          {addUrlId && (
            <AddUrlModal
              id={addUrlId}
              data={subchapters.find(subchapter => subchapter.id === addUrlId)}
              onSuccess={addUrlSuccess}
              open={!!addUrlId}
              handleClose={() => setAddUrlId(null)}
            />
          )}
        </>
      )}

      <LoadingModal open={isUploadingAsset} percent={0} />
    </div>
  );
}

export default CourseAdminSingleView;
