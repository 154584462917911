import axios from '../utils/axios';

export const getSubchaptersByChapterId = (chapterId) => {
  return axios.get('/CourseSubchapters/getSubchaptersByChapterId', {
    params: {
      chapterId,
    },
  });
};

export const createCourseSubchapter = (body, options) => {
  return axios.post('/CourseSubchapters/createSubchapter', body, options);
};

export const updateCourseSubchapter = (id, body, options) => {
  return axios.patch('/CourseSubchapters/updateSubchapter', body, {
    params: {
      id,
    },
  }, options);
};

export const uploadCourseSubchapterAsset = (id, formData) => {
  return axios.patch('/CourseSubchapters/uploadAsset', formData, {
    params: {
      id,
    },
  });
};

export const addUrl = (id, body) => {
  return axios.patch('/CourseSubchapters/addUrl', body, {
    params: {
      id,
    },
  });
}

export const deleteCourseSubchapter = (id) => {
  return axios.delete('/CourseSubchapters/deleteSubchapter', {
    params: {
      id,
    },
  });
};
