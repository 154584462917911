import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalFormWrapper } from 'src/components/ModalFormWrapper';
import CreateCourseForm from './CreateCourseForm';
import * as courseApi from './../../api/courseApi';

function CreateCourseModal({ onSuccess, open, handleClose }) {
  const [t] = useTranslation(["common", "newTranslations"]);

  const onSubmit = (values) => {
    courseApi.createCourse(values)
      .then(() => {
        onSuccess();
      });
  };

  const handleModalClose = () => {
    handleClose();
  };

  return (
    <ModalFormWrapper
      formTitle={t('newTranslations:createCourse')}
      handleOpen={open}
      handleClose={handleModalClose}
      maxWidth={"sm"}
    >
      <CreateCourseForm
        onSubmit={onSubmit}
        style={{ width: "100%" }}
      />
    </ModalFormWrapper>
  );
}
export default CreateCourseModal;
