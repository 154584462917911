import React from 'react';
import { Redirect } from 'react-router';

import { ADMIN, COREADMIN, SYSTEMADMIN } from './../constants/roles';
import { FULL, TA, TA_PRO } from './../constants/subscriptionPlansIds';

import CampaignList from './../views/Campaigns/CampaignList';
import Candidates from './../views/Candidates';
import CandidateDetails from './../views/Candidates/CandidateDetails';
import CandidateProfile from './../views/CandidateProfile';
import CampaignComponents from './../views/CampaignComponents';
import CampaignDetail from './../views/Campaigns/CampaignDetail';
import Consultancies from './../views/Consultancies';
import ConsultancyEmployers from './../views/ConsultancyEmployers';
import ConsultancyEmployerDetails from './../views/ConsultancyEmployerDetails';
import ConsultancyManagement from './../views/ConsultancyManagement';
import ContactManagement from './../views/ContactManagement';
import ChildConsultancyView from './../views/ConsultancyManagement/ChildConsultancyView';
import DWI from './../views/GlobalModuleSettings/Dwi';
import GroupDetails from './../views/MyProfile/Groups/GroupDetails';
import GroupDetailView from './../views/ConsultancyManagement/GroupDetailView';
import KCQ from './../views/GlobalModuleSettings/Kcq';
import SystemAdmin from './../views/SystemAdmin';
import SystemAdminAnalytics from './../views/SystemAdmin/SystemAdminAnalytics';
import SystemAdminCampaignList from './../views/SystemAdminCampaignList';
import SystemAdminEmployerList from './../views/SystemAdminEmployerList';
import SystemAdminSettings from './../views/SystemAdmin/SystemAdminSettings';
import SystemAdminUserList from './../views/SystemAdminUserList';
import TalentAlertCandidateProfile from './../views/TalentAlertConsultant/TalentAlertCandidatePool/TalentAlertCandidateProfile';
import TalentAlertConsultant from './../views/TalentAlertConsultant';
import TalentAlertDetail from './../views/TalentAlertConsultant/TalentAlertDetail';
import UserDetailView from './../views/ConsultancyManagement/UserDetailView';
import CoursesView from './../views/Courses';
import CourseAdminSingleView from './../views/Courses/CourseAdminSingleView';

const AdminRoutes = (store) => {

    const fullSubscriptionPlanRoutes = [
        {
            path: '/dashboard',
            exact: true,
            component: () => {
                if (store.getState().session.consultancySubscriptionPlanId !== FULL) {
                    return <Redirect to="/dashboard/talent-alerts" />;
                }

                switch (store.getState().session.roleType) {
                    case COREADMIN:
                    case SYSTEMADMIN:
                        return <Consultancies />;
                    default:
                        return <CampaignList />;
                }
            }
        },
        {
            path: '/dashboard/campaigns/:campaignId',
            exact: true,
            component: (props) => <CampaignDetail campaignId={ Number(props.match.params.campaignId) } />
        },
        {
            path: '/dashboard/campaigns/:campaignId/candidates/:candidateId',
            exact: true,
            component: (props) => <CandidateProfile campaignId={ Number(props.match.params.campaignId) } candidateId={ Number(props.match.params.candidateId) } />
        },
        {
            path: '/dashboard/consultancy/:childConsultancyId',
            exact: true,
            component: (props) => <ChildConsultancyView childConsultancyId={ Number(props.match.params.childConsultancyId) } />
        },
        {
            path: '/dashboard/consultancy/:childConsultancyId/employers/:employerId/contacts/:contactId',
            exact: true,
            component: (props) => <ChildConsultancyView childConsultancyId={ Number(props.match.params.childConsultancyId) } employerId={ Number(props.match.params.employerId) } contactId={ Number(props.match.params.contactId) } />
        },
        {
            path: '/dashboard/consultancy/:childConsultancyId/campaigns',
            exact: true,
            component: (props) => <ChildConsultancyView childConsultancyId={ Number(props.match.params.childConsultancyId) } />
        },
        {
            path: '/dashboard/consultancy/:childConsultancyId/campaigns/:campaignId',
            exact: true,
            component: (props) => <ChildConsultancyView childConsultancyId={ Number(props.match.params.childConsultancyId) } campaignId={ Number(props.match.params.campaignId) } />
        },
        {
            path: '/dashboard/consultancy/:childConsultancyId/campaigns/:campaignId/candidates/:candidateId',
            exact: true,
            component: (props) => <ChildConsultancyView childConsultancyId={ Number(props.match.params.childConsultancyId) } campaignId={ Number(props.match.params.campaignId) } candidateId={ Number(props.match.params.candidateId) } />
        },
        {
            path: '/dashboard/consultancy/:childConsultancyId/candidates/:candidateId',
            exact: true,
            component: (props) => <CandidateDetails childConsultancyId={ Number(props.match.params.childConsultancyId) } candidateId={ Number(props.match.params.candidateId) } />
        },

        // System Admin routes
        {
            path: '/dashboard/consultancies/:consultancyId',
            exact: true,
            component: (props) => {
                switch (store.getState().session.roleType) {
                    case SYSTEMADMIN:
                    case COREADMIN:
                        return <ConsultancyManagement consultancyId={ Number(props.match.params.consultancyId) } />;
                    default:
                        return <Redirect to={{ pathname: '/dashboard', state: { redirectPahtname: `/dashboard/consultancies/${props.match.params.consultancyId}` } }} />;
                }
            }
        },
        {
            path: '/dashboard/consultancies/:consultancyId/groups/:groupId',
            exact: true,
            component: (props) => {
                switch (store.getState().session.roleType) {
                    case SYSTEMADMIN:
                    case COREADMIN:
                        return <GroupDetailView consultancyId={ Number(props.match.params.consultancyId) } groupId={ Number(props.match.params.groupId) } />;
                    default:
                        return <Redirect to={{ pathname: '/dashboard', state: { redirectPahtname: `/dashboard/consultancies/${props.match.params.consultancyId}/groups/${props.match.params.groupId}` } }} />;
                }
            }
        },
        {
            path: '/dashboard/system-admins',
            exact: true,
            component: () => {
                switch (store.getState().session.roleType) {
                    case SYSTEMADMIN:
                    case COREADMIN:
                        return <SystemAdmin />;
                    default:
                        return <Redirect to={{ pathname: '/dashboard', state: { redirectPahtname: '/dashboard/system-admins' } }} />;
                }
            }
        },
        {
            path: '/dashboard/all-users',
            exact: true,
            component: () => {
                switch (store.getState().session.roleType) {
                    case SYSTEMADMIN:
                    case COREADMIN:
                        return <SystemAdminUserList />;
                    default:
                        return <Redirect to={{ pathname: '/dashboard', state: { redirectPahtname: '/dashboard/all-users' } }} />;
                }
            }
        },
        {
            path: '/dashboard/all-campaigns',
            exact: true,
            component: () => {
                switch (store.getState().session.roleType) {
                    case SYSTEMADMIN:
                    case COREADMIN:
                        return <SystemAdminCampaignList />;
                    default:
                        return <Redirect to={{ pathname: '/dashboard', state: { redirectPahtname: '/dashboard/all-users' } }} />;
                }
            }
        },
        {
            path: '/dashboard/all-employers',
            exact: true,
            component: () => {
                switch (store.getState().session.roleType) {
                    case SYSTEMADMIN:
                    case COREADMIN:
                        return <SystemAdminEmployerList />;
                    default:
                        return <Redirect to={{ pathname: '/dashboard', state: { redirectPahtname: '/dashboard/all-employers' } }} />;
                }
            }
        },
        {
            path: '/dashboard/all-courses',
            exact: true,
            component: () => {
                switch (store.getState().session.roleType) {
                    case SYSTEMADMIN:
                    case COREADMIN:
                        return <CoursesView isSystemAdmin={true} />;
                    default:
                        return <Redirect to={{ pathname: '/dashboard', state: { redirectPahtname: '/dashboard/retained-recruiter-academy' } }} />;
                }
            }
        },
        {
            path: '/dashboard/all-courses/:courseId',
            exact: true,
            component: (props) => {
                switch (store.getState().session.roleType) {
                    case SYSTEMADMIN:
                    case COREADMIN:
                        return <CourseAdminSingleView courseId={props.match.params.courseId} />;
                    default:
                        return <Redirect to={{ pathname: '/dashboard', state: { redirectPahtname: `/dashboard/retained-recruiter-academy/${props.match.params.courseId}` } }} />;
                }
            }
        },
        {
            path: '/dashboard/modules',
            exact: true,
            component: () => {
                switch (store.getState().session.roleType) {
                    case SYSTEMADMIN:
                    case COREADMIN:
                        return <CampaignComponents />;
                    default:
                        return <Redirect to={{ pathname: '/dashboard', state: { redirectPahtname: '/dashboard/modules' } }} />;
                }
            }
        },
        {
            path: '/dashboard/settings',
            exact: true,
            component: () => {
                switch (store.getState().session.roleType) {
                    case SYSTEMADMIN:
                    case COREADMIN:
                        return <SystemAdminSettings />;
                    default:
                        return <Redirect to={{ pathname: '/dashboard', state: { redirectPahtname: '/dashboard/settings' } }} />;
                }
            }
        },
        {
            path: '/dashboard/modules/kcq',
            exact: true,
            component: () => {
                switch (store.getState().session.roleType) {
                    case SYSTEMADMIN:
                    case COREADMIN:
                        return <KCQ />;
                    default:
                        return <Redirect to={{ pathname: '/dashboard', state: { redirectPahtname: '/dashboard/modules/kcq' } }} />;
                }
            }
        },
        {
            path: '/dashboard/modules/dwi',
            exact: true,
            component: () => {
                switch (store.getState().session.roleType) {
                    case SYSTEMADMIN:
                    case COREADMIN:
                        return <DWI />;
                    default:
                        return <Redirect to={{ pathname: '/dashboard', state: { redirectPahtname: '/dashboard/modules/dwi' } }} />;
                }
            }
        },
        {
            path: '/dashboard/system-admin-analytics',
            exact: true,
            component: () => {
                switch (store.getState().session.roleType) {
                    case SYSTEMADMIN:
                    case COREADMIN:
                        return <SystemAdminAnalytics />;
                    default:
                        return <Redirect to={{ pathname: '/dashboard', state: { redirectPahtname: '/dashboard/system-admin-analytics' } }} />;
                }
            }
        },

        // Consultancy Admin routes
        {
            path: '/dashboard/groups/:groupId',
            exact: true,
            component: (props) => {
                switch (store.getState().session.roleType) {
                    case ADMIN:
                        return <GroupDetailView consultancyId={ Number(store.getState().session.consultancyId) } groupId={ Number(props.match.params.groupId) } />;
                    default:
                        return <Redirect to={{ pathname: '/dashboard', state: { redirectPahtname: `/dashboard/groups/${props.match.params.groupId}` } }} />;
                }
            }
        },
        {
            path: '/dashboard/groups/:groupId/details',
            exact: true,
            component: (props) => {
                switch (store.getState().session.roleType) {
                    case ADMIN:
                        return <GroupDetails consultancyId={ Number(store.getState().session.consultancyId) } groupId={ Number(props.match.params.groupId) } />;
                    default:
                        return <Redirect to={{ pathname: '/dashboard', state: { redirectPahtname: `/dashboard/groups/${props.match.params.groupId}/details` } }} />;
                }
            }
        }
    ];

    const talentAlertSubscriptionPlanRoutes = [
        {
            path: '/dashboard/talent-alerts',
            exact: true,
            component: () => {
                switch (store.getState().session.roleType) {
                    case COREADMIN:
                    case SYSTEMADMIN:
                        return <Consultancies />;
                    default:
                        return <TalentAlertConsultant />;
                }
            }
        },
        {
            path: '/dashboard/talent-alerts/:campaignId',
            exact: true,
            component: (props) => <TalentAlertDetail campaignId={ Number(props.match.params.campaignId) } />
        },
        {
            path: '/dashboard/talent-alerts/:campaignId/candidates/:candidateId',
            exact: true,
            component: (props) => <TalentAlertCandidateProfile campaignId={ Number(props.match.params.campaignId) } candidateId={ Number(props.match.params.candidateId) } />
        },
        {
            path: '/dashboard/consultancy/:childConsultancyId/talent-alerts/:campaignId',
            exact: true,
            component: (props) => <ChildConsultancyView childConsultancyId={ Number(props.match.params.childConsultancyId) } campaignId={ Number(props.match.params.campaignId) } />
        },
        {
            path: '/dashboard/consultancy/:childConsultancyId/talent-alerts/:campaignId/candidates/:candidateId',
            exact: true,
            component: (props) => <ChildConsultancyView childConsultancyId={ Number(props.match.params.childConsultancyId) } campaignId={ Number(props.match.params.campaignId) } candidateId={ Number(props.match.params.candidateId) } />
        },

        // Consultancy Admin routes
        {
            path: '/dashboard/employers',
            exact: true,
            component: (props) => {
                switch (store.getState().session.roleType) {
                    case ADMIN:
                        return <ConsultancyEmployers candidateId={ Number(props.match.params.candidateId) } />;
                    default:
                        return <Redirect to={{ pathname: '/', state: { redirectPahtname: '/dashboard/employers' } }} />;
                }
            }
        },
        {
            path: '/dashboard/employers/:employerId',
            exact: true,
            component: (props) => {
                switch (store.getState().session.roleType) {
                    case ADMIN:
                        return <ConsultancyEmployerDetails employerId={ Number(props.match.params.employerId) } consultancyId={ Number(store.getState().session.consultancyId) } />;
                    default:
                        return <Redirect to={{ pathname: '/', state: { redirectPahtname: `/dashboard/employers/${props.match.params.employerId}` } }} />;
                }
            }
        },
        {
            path: '/dashboard/employers/:employerId/contacts/:contactId',
            exact: true,
            component: (props) => (
                <ContactManagement contactId={ Number(props.match.params.contactId) } consultancyId={ Number(store.getState().session.consultancyId) } employerId={ Number(props.match.params.employerId) } />
            )
        },
        {
            path: '/dashboard/consultancy-management',
            exact: true,
            component: () => {
                switch (store.getState().session.roleType) {
                    case ADMIN:
                        return <ConsultancyManagement consultancyId={ Number(store.getState().session.consultancyId) } />;
                    default:
                        return <Redirect to={{ pathname: '/dashboard', state: { redirectPahtname: '/dashboard/consultancy-management' } }} />;
                }
            }
        },
        {
            path: '/dashboard/users/:userId',
            exact: true,
            component: (props) => {
                switch (store.getState().session.roleType) {
                    case ADMIN:
                        return <UserDetailView consultancyId={ Number(store.getState().session.consultancyId) } userId={ Number(props.match.params.userId) } />;
                    default:
                        return <Redirect to={{ pathname: '/dashboard', state: { redirectPahtname: `/dashboard/users/${props.match.params.userId}` } }} />;
                }
            }
        },
        {
            path: '/dashboard/consultancies/:consultancyId/users/:userId',
            exact: true,
            component: (props) => {
                switch (store.getState().session.roleType) {
                    case SYSTEMADMIN:
                    case COREADMIN:
                        return <UserDetailView consultancyId={ Number(props.match.params.consultancyId) } userId={Number(props.match.params.userId)} />;
                    default:
                        return <Redirect to={{ pathname: '/dashboard', state: { redirectPahtname: `/dashboard/consultancies/${props.match.params.consultancyId}/users/${props.match.params.userId}` } }} />;
                }
            }
        },
        {
            path: '/dashboard/candidates',
            exact: true,
            component: () => {
                switch (store.getState().session.roleType) {
                    case ADMIN:
                        return <Candidates />;
                    default:
                        return <Redirect to={{ pathname: '/', state: { redirectPahtname: '/dashboard/candidates' } }} />;
                }
            }
        },
        {
            path: '/dashboard/candidates/:candidateId',
            exact: true,
            component: (props) => {
                switch (store.getState().session.roleType) {
                    case ADMIN:
                        return <CandidateDetails candidateId={ Number(props.match.params.candidateId) } />;
                    default:
                        return <Redirect to={{ pathname: '/', state: { redirectPahtname: `/dashboard/candidates/${props.match.params.candidateId}` } }} />;
                }
            }
        },
    ];

    const talentAlertProSubscriptionPlanRoutes = [
        {
            path: '/dashboard/vacancies',
            exact: true,
            component: () => <CampaignList />    
        },
        {
            path: '/dashboard/vacancies/:campaignId',
            exact: true,
            component: (props) => <CampaignDetail campaignId={ Number(props.match.params.campaignId) } />
        },
        {
            path: '/dashboard/vacancies/:campaignId/candidates/:candidateId',
            exact: true,
            component: (props) => <CandidateProfile campaignId={ Number(props.match.params.campaignId) } candidateId={ Number(props.match.params.candidateId) } />
        },
        ...talentAlertSubscriptionPlanRoutes
    ];

    if (store.getState().session.consultancySubscriptionPlanId === TA) {
        return talentAlertSubscriptionPlanRoutes;
    }

    if (store.getState().session.consultancySubscriptionPlanId === TA_PRO) {
        return talentAlertProSubscriptionPlanRoutes;
    }

    return [
        ...fullSubscriptionPlanRoutes,
        ...talentAlertSubscriptionPlanRoutes
    ];

};

export default AdminRoutes;
