import axios from '../utils/axios';

export const getCourseById = (id, consultancyId) => {
  return axios.get('/Courses/getCourseById', {
    params: {
      id,
      consultancyId,
    },
  });
};

export const getCourses = () => {
  return axios.get('/Courses/getCourses');
};

export const getCoursesForConsultancy = (consultancyId) => {
  return axios.get('/Courses/getCoursesForConsultancy', {
    params: {
      consultancyId,
    }
  });
};

export const searchCourses = (search, consultancyId) => {
  return axios.get('/Courses/searchCourses', {
    params: {
      search,
      consultancyId,
    },
  });
};

export const createCourse = (body, options) => {
  return axios.post('/Courses/createCourse', body, options);
};

export const uploadThumbnail = (id, formData) => {
  return axios.post('/Courses/uploadThumbnail', formData, {
    params: {
      id,
    },
  });
};

export const updateCourse = (id, body) => {
  return axios.patch('/Courses/updateCourse', body, {
    params: {
      id,
    },
  });
};

export const suspendConsultancy = (id, consultancyId) => {
  return axios.patch('/Courses/suspendConsultancy', {}, {
    params: {
      id,
      consultancyId,
    },
  });
};

export const deleteCourse = (id) => {
  return axios.delete('/Courses/deleteCourse', {
    params: {
      id,
    },
  });
};
