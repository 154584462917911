import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  CardContent,
  Grid,
  Link,
  Card,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  colors,
  IconButton,
  useMediaQuery,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ContentWrapper } from "src/components/LayoutComponents/ContentWrapper";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import * as courseApi from "./../../api/courseApi";
import ReactPlayer from "react-player";

const useStyles = makeStyles((theme) => ({
  gridRow: {
    marginTop: "20px",
  },
  iframeGoone: {
    border: "none",
    marginRight: "50px",
  },
  link_with_pointer: {
    cursor: "pointer",
    marginTop: "64px",
    marginBottom: "4px",
  },
  chapterTitle: {
    fontWeight: "bold",
  },
  selectedSubchapter: {
    backgroundColor: theme.palette.action.selected,
  },
  divider: {
    width: '100%',
    height: '1px',
    borderBottom: `1px solid ${colors.grey[200]}`,
    marginBottom: '12px',
  },
  title: {
    fontSize: '20px',
    fontWeight: 500,
  },
  subtitle: {
    margin: '12px 0px',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  overviewText: {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

function LearningHubAnon({ ...props }) {
  const history = useHistory();
  const classes = useStyles();
  const [t] = useTranslation(["common", "snackbar"]);
  const [course, setCourse] = useState(null);
  const [expandedChapter, setExpandedChapter] = useState(null);
  const [selectedSubchapter, setSelectedSubchapter] = useState(null);

  const contentRef = useRef(null);

  const consultancyId = useSelector((state) => state.session.consultancyId);

  const desktop = useMediaQuery("(min-width:1280px)");

  useEffect(() => {
    fetchCourse();
  }, []);

  const fetchCourse = () => {
    courseApi
      .getCourseById(props.courseId, consultancyId)
      .then((response) => {
        setCourse(response.data);
        if (response.data.chapters && response.data.chapters.length > 0) {
          setExpandedChapter(response.data.chapters[0].id);
          setSelectedSubchapter({...response.data.chapters[0].subchapters[0], title: '1.1. ' + response.data.chapters[0].subchapters[0].title});
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleChange = (chapterId) => (event, isExpanded) => {
    setExpandedChapter(isExpanded ? chapterId : null);
  };

  const handleSubchapterClick = (subchapter) => {
    setSelectedSubchapter(subchapter);

    if (!desktop && contentRef.current) {
      contentRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  const handleBackClick = () => {
    const chapterId = Number(selectedSubchapter.title.split('.')[0]) - 1;
    const subchapterId = Number(selectedSubchapter.title.split('.')[1]) - 1;

    if (subchapterId - 1 >= 0) {
      const data = course.chapters[chapterId].subchapters[subchapterId - 1];
      setSelectedSubchapter({...data, title: (chapterId + 1) + '.' + (subchapterId) + '. ' + data.title});
      setExpandedChapter(course.chapters[chapterId].id);
    } else if (chapterId - 1 >= 0) {
      const lastSubchapter = course.chapters[chapterId - 1].subchapters.length - 1;
      const data = course.chapters[chapterId - 1].subchapters[lastSubchapter];
      setSelectedSubchapter({...data, title: (chapterId) + '.' + (lastSubchapter + 1) + '. ' + data.title});
      setExpandedChapter(course.chapters[chapterId - 1].id);
    }
    else {
      return;
    }

    if (!desktop && contentRef.current) {
      contentRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }

  const handleForwardClick = () => {
    const chapterId = Number(selectedSubchapter.title.split('.')[0]) - 1;
    const subchapterId = Number(selectedSubchapter.title.split('.')[1]) - 1;

    const lastChapter = course.chapters.length - 1;
    const lastSubchapter = course.chapters[chapterId].subchapters.length - 1;

    if (subchapterId + 1 <= lastSubchapter) {
      const data = course.chapters[chapterId].subchapters[subchapterId + 1];
      setSelectedSubchapter({...data, title: (chapterId + 1) + '.' + (subchapterId + 2) + '. ' + data.title});
      setExpandedChapter(course.chapters[chapterId].id);
    } else if (chapterId + 1 <= lastChapter) {
      const data = course.chapters[chapterId + 1].subchapters[0];
      setSelectedSubchapter({...data, title: (chapterId + 2) + '.' + 1 + '. ' + data.title});
      setExpandedChapter(course.chapters[chapterId + 1].id);
    }
    else {
      return;
    }

    if (!desktop && contentRef.current) {
      contentRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }

  return (
    <div style={{ overflow: "hidden", paddingBottom: "20px" }}>
      <ContentWrapper>
        <Grid container>
          <div
            className={classes.link_with_pointer}
            onClick={() => history.goBack()}
            role="button"
            style={{ cursor: "pointer" }}
          >
            <Grid container>
              <Grid item xs={6}>
                <ArrowBackIcon />
              </Grid>
              <Grid item xs={6}>
                {t("common:goBack")}
              </Grid>
            </Grid>
          </div>
        </Grid>

        {course && (
          <Grid container spacing={3} style={{ marginTop: "20px" }} direction={desktop ? 'row-reverse' : 'row'}>
            <Grid item lg={4} xs={12}>
              <Card style={{ marginBottom: 20 }}>
                <CardContent style={{ paddingLeft: 24, paddingRight: 24 }}>
                  <Typography variant="body1" className={classes.title}>{course.title}</Typography>
                  <Typography variant="body2" className={classes.subtitle}>{course.subtitle}</Typography>
                  <Typography variant="body2" color="textSecondary" className={classes.overviewText}>{course.overview}</Typography>
                </CardContent>
              </Card>

              {course.chapters.map((chapter, chapterIndex) => (
                <ExpansionPanel
                  key={chapter.id}
                  expanded={expandedChapter === chapter.id}
                  onChange={handleChange(chapter.id)}
                >
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`chapter-${chapter.id}-content`}
                    id={`chapter-${chapter.id}-header`}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Typography variant="body1" style={{ fontWeight: 500 }}>{chapterIndex + 1}. {chapter.title}</Typography>
                      <Typography variant="body2" color="textSecondary" className={classes.overviewText}>
                        {chapter.overview}
                      </Typography>
                    </div>
                  </ExpansionPanelSummary>

                  <ExpansionPanelDetails>
                    <div style={{ width: "100%" }}>
                      <div className={ classes.divider }></div>
                      {chapter.subchapters.length > 0 ? (
                        <List>
                          {chapter.subchapters.map((subchapter, subchapterIndex) => (
                            <>
                              {(subchapter.asset || subchapter.url) && (
                                <>
                                  <ListItem
                                    key={subchapter.id}
                                    button
                                    onClick={() => handleSubchapterClick({...subchapter, title: (chapterIndex + 1) + '.' + (subchapterIndex + 1) + '. ' + subchapter.title})}
                                    className={
                                      selectedSubchapter && selectedSubchapter.id === subchapter.id
                                        ? classes.selectedSubchapter
                                        : ""
                                    }
                                  >
                                    <ListItemText primary={(chapterIndex + 1) + '.' + (subchapterIndex + 1) + '. ' + subchapter.title} secondary={subchapter.assetType === 'pdf' ? 'Document' : 'Video'} />
                                  </ListItem>

                                  {subchapterIndex !== chapter.subchapters.length - 1 && (
                                    <Divider />
                                  )}
                                </>
                              )}
                            </>
                          ))}
                        </List>
                      ) : (
                        <span>{t("newTranslations:noCourseSubchapters")}</span>
                      )}
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              ))}
            </Grid>

            <Grid item lg={8} xs={12} ref={contentRef}>
              <Card>
                <CardContent>
                  {(selectedSubchapter && (selectedSubchapter.asset || selectedSubchapter.url)) && (
                    <>
                      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <span style={{ fontWeight: 500 }}>{selectedSubchapter.title}</span>

                        <div style={{display: 'flex', columnGap: 8}}>
                          <IconButton onClick={() => handleBackClick()}>
                            <ArrowBackIcon style={{ fontSize: 18 }} />
                          </IconButton>

                          <IconButton onClick={() => handleForwardClick()}>
                            <ArrowForwardIcon style={{ fontSize: 18 }} />
                          </IconButton>
                        </div>
                      </div>

                      <div className={classes.divider} style={{ margin: '20px 0px' }}></div>

                      {selectedSubchapter.assetType.toLowerCase() === "pdf" && (
                        <iframe
                          src={selectedSubchapter.asset ? selectedSubchapter.asset.value : selectedSubchapter.url}
                          title="PDF Viewer"
                          width="100%"
                          height="600px"
                          style={{ border: `1px solid ${colors.grey[200]}` }}
                        />
                      )}

                      {selectedSubchapter.assetType.toLowerCase() === "video" && (
                        <>
                          {selectedSubchapter.asset ? (
                            <video
                              key={selectedSubchapter.asset.value}
                              controls
                              width="100%"
                              height="auto"
                              style={{ maxHeight: "600px", backgroundColor: colors.grey[200] }}
                            >
                              <source src={selectedSubchapter.asset.value} />
                              Your browser does not support the video tag.
                            </video>
                          ) : (
                            <div style={{position: 'relative', paddingTop: '56.25%'}}>
                              <ReactPlayer
                                url={selectedSubchapter.url}
                                width="100%"
                                height="100%"
                                style={{
                                  position: 'absolute',
                                  top: 0,
                                  left: 0,
                                }}
                              />
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}
      </ContentWrapper>
    </div>
  );
}

export default LearningHubAnon;
