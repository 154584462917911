import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalFormWrapper } from 'src/components/ModalFormWrapper';
import EditCourseChapterForm from './EditCourseChapterForm';
import * as courseChapterApi from '../../api/courseChapterApi';
import * as courseSubchapterApi from '../../api/courseSubchapterApi';

function EditCourseChapterModal({ type, onSuccess, open, handleClose, chapter }) {
  const [t] = useTranslation(["common", "newTranslations"]);

  const onSubmit = async (values) => {
    if (type === 'chapter') {
      await courseChapterApi.updateCourseChapter(chapter.id, values);
    }
    else {
      await courseSubchapterApi.updateCourseSubchapter(chapter.id, values);
    }

    onSuccess();
  };

  const handleModalClose = () => {
    handleClose();
  };

  return (
    <ModalFormWrapper
      formTitle={t(`newTranslations:${type === 'chapter' ? 'editCourseChapter' : 'editCourseSubchapter'}`)}
      handleOpen={open}
      handleClose={handleModalClose}
      maxWidth={"sm"}
    >
      <EditCourseChapterForm
        type={type}
        onSubmit={onSubmit}
        title={chapter.title}
        overview={chapter.overview}
        style={{ width: "100%" }}
      />
    </ModalFormWrapper>
  );
}
export default EditCourseChapterModal;
