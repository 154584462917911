import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalFormWrapper } from 'src/components/ModalFormWrapper';
import CreateCourseForm from './CreateCourseForm';
import * as courseSubchapterApi from './../../api/courseSubchapterApi';
import AddUrlForm from './AddUrlForm';

function AddUrlModal({ id, data, onSuccess, open, handleClose }) {
  const [t] = useTranslation(["common", "newTranslations"]);

  const onSubmit = (values) => {
    courseSubchapterApi.addUrl(id, values)
      .then(() => {
        onSuccess();
      })
  };

  const handleModalClose = () => {
    handleClose();
  };

  return (
    <ModalFormWrapper
      formTitle={data.url ? t('newTranslations:changeUrl') : t('newTranslations:addUrl')}
      handleOpen={open}
      handleClose={handleModalClose}
      maxWidth={"sm"}
    >
      <AddUrlForm
        data={data}
        onSubmit={onSubmit}
        style={{ width: "100%" }}
      />
    </ModalFormWrapper>
  );
}
export default AddUrlModal;
