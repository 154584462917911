import React, { useEffect, useState } from 'react';
import { useSnackbar } from "notistack";
import { makeStyles } from '@material-ui/styles';
import {
  TextField,
  colors,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Tooltip,
  Grid, Link, Card, CardContent, Button
} from '@material-ui/core';
import validate from 'validate.js';
import * as ConsultancyApi from 'src/api/consultancyApi';
import { useTranslation } from 'react-i18next';
import InfoIcon from '@material-ui/icons/Info';

import * as courseApi from '../../api/courseApi';

import { BlockHeader } from "src/components/LayoutComponents/BlockHeader";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  container: {
    padding: '0px 10px',
  },
  fields: {
    margin: theme.spacing(-1),
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: '33%',
  },
  modalCard: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
      transform: `translate(${0}%, ${10}%)`,
    },
    [theme.breakpoints.up('md')]: {
      width: '50%',
      transform: `translate(${50}%, ${10}%)`,
    },
    '&:focus': {
      outline: 'none',
    },
  },
  closeButton: {
    minWidth: '32px',
    backgroundColor: colors.red[600],
    '&:hover': {
      backgroundColor: colors.red[400],
    },
    color: theme.palette.common.white,
    textTransform: 'none',
  },
  modalOverflow: {
    overflowY: 'scroll',
  },
  tooltip: {
    textAlign: 'center',
    padding: 2,
    margin: 0,
  },
}));

function EditTitlesCard({ course, onSuccess }) {
  const classes = useStyles();

  const [t] = useTranslation(['common', 'validation', 'vacancies']);

  const { enqueueSnackbar } = useSnackbar();

  const [isValid, setIsValid] = useState(false);

  const [forceUpdate, setForceUpdate] = useState(true);

  const [initialFormState, setInitialFormState] = useState();

  const [formState, setFormState] = useState({
    values: {
      title: course.title || '',
      subtitle: course.subtitle || '',
      overview: course.overview || '',
    },
    touched: {},
    errors: {},
  });

  useEffect(() => {
    if (!initialFormState) {
      setInitialFormState(formState);
      return
    }

    if (forceUpdate) {
      setForceUpdate(false);
    }

    if (formState.values.title.trim() && JSON.stringify(initialFormState.values) !== JSON.stringify(formState.values)) {
      setIsValid(true);
    }
    else {
      setIsValid(false);
    }
  }, [formState.values, forceUpdate]);

  const handleChange = (event) => {
    event.persist();
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const hasError = (field) => {
    if (formState.touched[field] && formState.errors[field]) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    courseApi.updateCourse(course.id, formState.values)
      .then(() => {
        setInitialFormState(formState)
        setForceUpdate(true)

        onSuccess();

        enqueueSnackbar(t("snackbar:updateCourse"), {
          variant: "success",
        });
      })
      .catch(error => {
        enqueueSnackbar(t("snackbar:failedToUpdateCourse"), {
          variant: "error",
        });
      });
  }

  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} style={{ textAlign: "left" }}>
            <BlockHeader
              card
              header={t("labels:manageTitles")}
            />

            <form onSubmit={handleSubmit}>
              <div className={classes.fields}>
                <TextField
                  fullWidth
                  label={t('common:title')}
                  name='title'
                  variant='outlined'
                  required
                  onChange={handleChange}
                  error={hasError('title')}
                  value={formState.values.title || ''}
                  helperText={
                    hasError('title')
                      ? t(`validation:${formState.errors.title[0]}`)
                      : null
                  }
                />

                <TextField
                  fullWidth
                  label={t('common:subtitle')}
                  name='subtitle'
                  variant='outlined'
                  onChange={handleChange}
                  error={hasError('title')}
                  value={formState.values.subtitle || ''}
                  helperText={
                    hasError('subtitle')
                      ? t(`validation:${formState.errors.subtitle[0]}`)
                      : null
                  }
                />

                <TextField
                  multiline
                  rows={4}
                  minRows={4}
                  fullWidth
                  label={t('common:overview')}
                  name='overview'
                  variant='outlined'
                  onChange={handleChange}
                  error={hasError('overview')}
                  value={formState.values.overview || ''}
                  helperText={
                    hasError('overview')
                      ? t(`validation:${formState.errors.overview[0]}`)
                      : null
                  }
                />
              </div>

              <div style={{ textAlign: 'right' }}>
                <Button
                  className={classes.submitButton}
                  color='primary'
                  size='medium'
                  type='submit'
                  variant='contained'
                  fullWidth
                  disabled={!isValid}
                >
                  {t('common:update')}
                </Button>
              </div>
            </form>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
export default EditTitlesCard;
