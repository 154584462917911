import React, { useState, useEffect } from "react";
import { AppBar, Grid, Tab, Tabs } from "@material-ui/core";
import { TabPanel } from "src/components/TabPanel";
import { ToggleCard } from "src/components/Cards/ToggleCard";
import { Searchbar, useSearch } from "src/components/Filter/Search";
import { useTranslation } from "react-i18next";
import * as api from "../../api/SystemAdminApi";
import * as consultancyApi from "../../api/consultancyApi";
import * as courseApi from './../../api/courseApi';

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
	appBar: {
		boxShadow: "none",
		backgroundColor: "transparent",
		marginTop: "2rem",
		marginBottom: "-4rem",
	},
}));

const CoursesManagement = ({ consultancyId }) => {
	const classes = useStyles();
	const [t] = useTranslation(["settings", "newTranslations"]);

	const courseTabs = [
		{ value: 0, label: "availableCourses" },
		{ value: 1, label: "activeCourses" },
		{ value: 2, label: "inactiveCourses" },
	];

	const idArr = [];

	const [availableCourses, setAvailableCourses] = useState([]);
	const [activeCourses, setActiveCourses] = useState([]);
	const [inactiveCourses, setInactiveCourses] = useState([]);
	const [currentTab, setCurrentTab] = useState(0);
	const [searchTerm, setSearchTerm] = useState("");

	const getActiveCourses = () => {
		return courseApi
			.getCoursesForConsultancy(consultancyId)
			.then((response) => {
				const data = response.data;
				const activeCoursesArr = data.map((course) => {
					return {
						id: course.id,
						title: course.title,
						isActive: !course.suspendedConsultancies.includes(consultancyId),
					};
				});

				setActiveCourses(activeCoursesArr);

				for (const e of data) {
					idArr.push(e.id);
				}

				getAvailableCourses();
			})
			.catch((error) => {
				console.error(error);
				throw error;
			});
	};

	const getAvailableCourses = () => {
		return courseApi
			.getCourses()
			.then((response) => {
				const data = response.data;
				const availableCoursesArr = data.map((course) => {
					return {
						id: course.id,
						title: course.title,
						isActive: !course.suspendedConsultancies.includes(consultancyId),
					};
				});
				setAvailableCourses(availableCoursesArr);
				const inactive = availableCoursesArr.filter(
					(e) => e.isActive === false
				);
				setInactiveCourses(inactive);
			})
			.catch((error) => {
				console.error(error);
				throw error;
			});
	};

	useEffect(() => {
		getActiveCourses();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [consultancyId]);

	const handleInputChange = (newValue) => {
		setSearchTerm(newValue);
	};

	const handleTabChange = (event, value) => {
		setCurrentTab(value);
	};

	const handleSuspend = (id) => {
		return courseApi.suspendConsultancy(id, consultancyId).then((response) => {
			if (response) {
				getActiveCourses();
			}
		});
	}

	let filteredResults = useSearch(
		currentTab === 0
			? availableCourses
			: currentTab === 1
			? activeCourses
			: inactiveCourses,
		searchTerm,
		["title"]
	).searchResults();

	return (
		<>
			<Grid
				container
				xs={12}
				display="flex"
				alignItems="center"
				flexDirection="row-reverse"
			>
				<Searchbar onChange={handleInputChange} />
			</Grid>
			<Grid container spacing={5}>
				<Grid item xs={12} className={classes.gridRow}>
					<AppBar position="static" className={classes.appBar}>
						<Tabs
							value={currentTab}
							onChange={handleTabChange}
							variant="scrollable"
							textColor="primary"
						>
							{courseTabs.map((tab) => (
								<Tab
									key={tab.value}
									label={t(`newTranslations:${tab.label}`)}
									value={tab.value}
								/>
							))}
						</Tabs>
					</AppBar>
				</Grid>
				<Grid item xs={12} className={classes.gridRow}>
					<TabPanel value={currentTab} index={0}>
						<Grid container spacing={3}>
							{filteredResults.map((course) => (
								<ToggleCard
									key={course.id}
									name={course.title}
									enabled={course.isActive}
									handleSwitch={() => handleSuspend(course.id)}
								/>
							))}
						</Grid>
					</TabPanel>

					<TabPanel value={currentTab} index={1}>
						<Grid container spacing={3}>
							{filteredResults.map((course) => (
								<ToggleCard
									key={course.id}
									name={course.title}
									enabled={course.isActive}
									handleSwitch={() => handleSuspend(course.id)}
								/>
							))}
						</Grid>
					</TabPanel>

					<TabPanel value={currentTab} index={2}>
						<Grid container spacing={3}>
							{filteredResults.map((course) => (
								<ToggleCard
									key={course.id}
									name={course.title}
									enabled={course.isActive}
									handleSwitch={() => handleSuspend(course.id)}
								/>
							))}
						</Grid>
					</TabPanel>
				</Grid>
			</Grid>
		</>
	);
};

export default CoursesManagement;
