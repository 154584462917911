import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  TextField,
  Button,
  colors,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  container: {
    padding: '0px 10px',
  },
  fields: {
    margin: theme.spacing(-1),
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: '33%',
  },
  modalCard: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
      transform: `translate(${0}%, ${10}%)`,
    },
    [theme.breakpoints.up('md')]: {
      width: '50%',
      transform: `translate(${50}%, ${10}%)`,
    },
    '&:focus': {
      outline: 'none',
    },
  },
  closeButton: {
    minWidth: '32px',
    backgroundColor: colors.red[600],
    '&:hover': {
      backgroundColor: colors.red[400],
    },
    color: theme.palette.common.white,
    textTransform: 'none',
  },
  modalOverflow: {
    overflowY: 'scroll',
  },
  tooltip: {
    textAlign: 'center',
    padding: 2,
    margin: 0,
  },
}));

function AddUrlForm({ data, onSubmit }) {
  const classes = useStyles();
  const [t] = useTranslation(['common', 'validation']);

  const initialValues = {
    assetType: data.assetType || 'video',
    url: data.url || '',
  };

  const [isValid, setIsValid] = useState(false);

  const [formState, setFormState] = useState({
    values: initialValues,
    touched: {},
    errors: {},
  });

  const assetTypeOptions = [
    {
      label: 'PDF',
      value: 'pdf',
    },
    {
      label: t('common:video'),
      value: 'video',
    },
  ];

  useEffect(() => {
    const assetTypeChanged = formState.values.assetType !== initialValues.assetType;
    const urlChanged = formState.values.url !== initialValues.url;
    const hasContent = formState.values.assetType.trim() && formState.values.url.trim();

    if ((assetTypeChanged || urlChanged) && hasContent) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [formState.values, initialValues]);

  const handleChange = (event) => {
    event.persist();
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const hasError = (field) => {
    if (formState.touched[field] && formState.errors[field]) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(formState.values);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.fields}>
        <TextField
          label={t('common:assetType')}
          name="assetType"
          onChange={handleChange}
          select
          SelectProps={{ native: true }}
          value={formState.values.assetType}
          variant="outlined"
          required
        >
          {assetTypeOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </TextField>

        <TextField
          fullWidth
          label={t('common:url')}
          name="url"
          variant="outlined"
          required
          onChange={handleChange}
          error={hasError('url')}
          value={formState.values.url || ''}
          helperText={
            hasError('url')
              ? t(`validation:${formState.errors.url[0]}`)
              : null
          }
        />
      </div>
      <div style={{ textAlign: 'right' }}>
        <Button
          className={classes.submitButton}
          color="primary"
          size="medium"
          type="submit"
          variant="contained"
          fullWidth
          disabled={!isValid}
        >
          {data.url ? t('newTranslations:changeUrl') : t('newTranslations:addUrl')}
        </Button>
      </div>
    </form>
  );
}

export default AddUrlForm;
