import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Card,
  Box,
  useMediaQuery,
  TextField
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { CourseCard } from '../../components/Cards/CourseCard';
import { ContentWrapper } from '../../components/LayoutComponents/ContentWrapper';
import { PageTitle } from '../../components/LayoutComponents/PageTitle';
import CreateCourseModal from './CreateCourseModal';
import * as courseApi from '../../api/courseApi';
import { Searchbar } from 'src/components/Filter/Search';
import { Paginator, usePagination } from 'src/components/Filter/Pagination';
import { SelectMenu } from 'src/components/Filter/Select';

const useStyles = makeStyles((theme) => ({
  filterCard: {
    width: '100%',
    padding: 0,
  },
  filterCard_box: {
    padding: 0,
  },
  sort: {
    width: 260,
    marginRight: theme.spacing(2),
  },
}));

function CoursesView({ isSystemAdmin = false }) {
  const [t] = useTranslation(['common', 'newTranslations']);
  const classes = useStyles();

  const [courses, setCourses] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showItems, setShowItems] = useState(10);
  const [sortOrder, setSortOrder] = useState('createdAt|desc');
  const [open, setOpen] = useState(false);

  const consultancyId = useSelector((state) => state.session.consultancyId);
  const desktop = useMediaQuery('(min-width:960px)');

  const sortOptions = [
    {
      value: 'createdAt|desc',
      label: `${t('common:createdAt')} (${t('common:latest')})`,
    },
    {
      value: 'createdAt|asc',
      label: `${t('common:createdAt')} (${t('common:oldest')})`,
    },
  ];

  const { currentData, jump, maxPage } = usePagination(courses, showItems);

  useEffect(() => {
    if (!searchTerm.trim()) {
      fetchCourses();
    } else {
      searchCourses(searchTerm.trim());
    }
  }, [searchTerm]);

  useEffect(() => {
    fetchCourses();
  }, []);

  const fetchCourses = () => {
    const apiCall = isSystemAdmin
      ? courseApi.getCourses()
      : courseApi.getCoursesForConsultancy(consultancyId);

    apiCall
      .then((response) => setCourses(response.data))
      .catch((error) => console.error(error));
  };

  const searchCourses = (search) => {
    courseApi.searchCourses(search, consultancyId)
      .then((response) => setCourses(response.data))
      .catch((error) => console.error(error));
  };

  const handleInputChange = (newValue) => {
    setSearchTerm(newValue);
  };

  const handleShowItems = (newValue) => {
    setShowItems(newValue);
    jump(1);
  };

  const handlePageChange = (newValue) => {
    jump(newValue.selected + 1);
  };

  const handleSortItems = (event) => {
    event.persist();
    setSortOrder(event.target.value);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string') {
      return b[orderBy].localeCompare(a[orderBy]);
    } else {
      return b[orderBy] - a[orderBy];
    }
  };

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const applySort = (data, sortOrder) => {
    const [orderBy, order] = sortOrder.split('|');
    const comparator = getComparator(order, orderBy);
    const stabilizedThis = data.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      return order !== 0 ? order : a[1] - b[1];
    });

    return stabilizedThis.map((el) => el[0]);
  };

  const onSuccess = () => {
    setOpen(false);
    fetchCourses();
  };

  return (
    <div style={{ overflow: 'hidden', paddingBottom: '20px' }}>
      <PageTitle
        pageTitle={t('newTranslations:retainedrecruiteracademy')}
        {...(isSystemAdmin && {
          btnLabel: t('newTranslations:createCourse'),
          btnFunc: () => setOpen(true)
        })}
      />

      <ContentWrapper>
        <Card className={classes.filterCard}>
          <Box
            p={2}
            minHeight={56}
            display='flex'
            alignItems='center'
            className={classes.filterCard_box}
          >
            <Searchbar onChange={handleInputChange} />
            <Box flexGrow={1} />
            {desktop && (
              <>
                <SelectMenu value={showItems} onChange={handleShowItems} />
                <TextField
                  label={t('common:sortBy')}
                  name='sort'
                  onChange={handleSortItems}
                  select
                  SelectProps={{ native: true }}
                  value={sortOrder}
                  variant='outlined'
                  className={classes.sort}
                >
                  {sortOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </>
            )}
          </Box>
        </Card>

        <Grid container spacing={3}>
          {applySort(currentData(), sortOrder).map((course) => (
            <CourseCard
              key={course.id}
              course={course}
              btnLabel={isSystemAdmin ? t('common:viewMore') : t('common:play')}
              btnFunc={
                isSystemAdmin
                  ? `/dashboard/all-courses/${course.id}`
                  : `/dashboard/retained-recruiter-academy/${course.id}`
              }
            />
          ))}
        </Grid>

        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            margin: '40px 0px'
          }}
        >
          {currentData().length === 0 ? (
            <span>{t('newTranslations:noCourses')}</span>
          ) : (
            <Paginator pageCount={maxPage} onPageChange={handlePageChange} />
          )}
        </div>
      </ContentWrapper>

      {isSystemAdmin && (
        <CreateCourseModal
          onSuccess={onSuccess}
          open={open}
          handleClose={() => setOpen(false)}
        />
      )}
    </div>
  );
}

export default CoursesView;
