import React from "react";
import { Link } from 'react-router-dom';
import { makeStyles } from "@material-ui/styles";
import {
  Box,
  Button,
  Card,
  Grid,
  CardActions,
  CardContent,
  Typography,
} from "@material-ui/core";
import logoPlaceholder from "src/assets/images/placeholder.png";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    transition: "300ms",
    maxWidth: "260px",
    minHeight: "360px",
    height: 'fit-content',
    overflow: "visible",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: theme.spacing(12),
    paddingBottom: theme.spacing(1),
  },
  card_disabled: {
    backgroundColor: "transparent",
    color: "grey",
    transition: "300ms",
  },
  name: {
    transition: "300ms",
    width: "100%",
    marginBottom: 0,
  },
  logo_container: {
    width: "100%",
    height: "170px",
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    overflow: 'hidden',
  },
  logo: {
    height: "100%",
    width: "100%",
    backgroundPosition: 'center',
    objectFit: 'cover',
  },
}));

export const CourseCard = ({
  course,
  btnLabel,
  btnFunc,
}) => {
  const classes = useStyles();
  const [t] = useTranslation(["common"]);

  return (
    <Grid key={course.id} item md={3} sm={4} xs={12}>
      <Card className={classes.card}>
        <Box className={classes.logo_container}>
          <img
            src={course.thumbnail ? course.thumbnail : logoPlaceholder}
            className={classes.logo}
            alt=""
          />
        </Box>

        <CardContent>
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            textAlign="center"
          >
            <Box>
              <Typography
                className={classes.name}
                gutterBottom
                variant="h3"
                color="textPrimary"
              >
                {course.title}
              </Typography>
            </Box>
          </Box>
        </CardContent>

        <CardActions>
          <Button
            fullWidth
            component={Link}
            to={btnFunc}
            variant="text"
            color="primary"
          >
            {btnLabel}
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};
